var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"event"},[(_vm.event.image != '' && _vm.event.image != null)?_c('div',{staticClass:"banner",style:({
      backgroundImage:
        'url(' +
        _vm.img_url +
        encodeURIComponent(_vm.event.image).replace('%2F', '/') +
        ')',
    })}):_vm._e(),_c('div',{staticClass:"container-event"},[_c('div',{staticClass:"aside",style:(_vm.event.category == 'News'
          ? {
              backgroundImage:
                'url(' +
                _vm.img_url +
                encodeURIComponent(_vm.event.image2).replace('%2F', '/') +
                ')',
            }
          : {
              backgroundImage:
                'none',
            })},[(_vm.event.category == 'event' || _vm.event.category == 'Event')?_c('div',{staticClass:"event-info"},[_c('div',{staticClass:"date-container"},[_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.event.date))]),_c('p',{staticClass:"hour"},[_vm._v(_vm._s(_vm.event.start_hour.slice(0, 5)))])]),_c('div',{staticClass:"address-container"},[_c('p',{staticClass:"location"},[_c('span',{staticClass:"icon-icon-location"}),_vm._v(" "+_vm._s(_vm.event.location)+" ")]),_c('p',{staticClass:"address"},[_vm._v(_vm._s(_vm.event.address))])]),_c('div',{staticClass:"price-container"},[(_vm.event.price > 0)?_c('p',{staticClass:"price"},[_vm._v(_vm._s(_vm.event.price)+" CHF")]):_vm._e(),(_vm.event.price == 0)?_c('p',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.content.events.free)+" ")]):_vm._e()]),_c('a',{staticClass:"button-fill",on:{"click":_vm.registerToEvent}},[_vm._v(_vm._s(_vm.content.events.btn_inscrever))])]):_vm._e()]),_c('div',{staticClass:"description"},[(_vm.event.category == 'Event')?_c('p',{staticClass:"category"},[_vm._v(" "+_vm._s(_vm.content.events.event)+" ")]):_vm._e(),(_vm.event.category == 'News')?_c('p',{staticClass:"category"},[_vm._v(" "+_vm._s(_vm.content.events.news)+" ")]):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.event.title))]),_c('p',{staticClass:"description_event",domProps:{"innerHTML":_vm._s(_vm.event.description)}})])]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',{staticClass:"mx-auto dialog-card"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.content.dialogs.events.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.content.dialogs.events.description)}}),_c('a',{on:{"click":function($event){_vm.dialog2 = false}}},[_c('span',{staticClass:"icon-icon-close"})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }